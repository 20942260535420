
import { ref } from 'vue'
import { login, useUser } from '@/utils/login'
import { useRouter } from 'vue-router'

export default {
  name: 'Login',
  setup () {
    const username = ref('')
    const password = ref('')
    const router = useRouter()
    const user = useUser()

    async function onLogin () {
      if (username.value && password.value) {
        const response = await login(username.value, password.value)

        if (response) {
          // Erfolgreicher Login, leiten Sie den Benutzer zur Hauptseite weiter
          router.push('/')
        } else {
          alert('Benutzername oder Passwort falsch')
        }
      }
    }

    return {
      username,
      password,
      onLogin
    }
  }
}

import axios from 'axios'
import { ref, Ref } from 'vue'

const tokenKey = 'carab-pos-token'
const userKey = 'carab-pos-user'

const token: Ref<string> = ref('')
const user: Ref = ref(null)

const savedToken = localStorage.getItem(tokenKey)
const savedUser = localStorage.getItem(userKey)

if (savedToken) {
  token.value = savedToken
}

if (savedUser) {
  user.value = JSON.parse(savedUser)
}

export async function login (username: string, password: string): Promise<boolean> {
  try {
    const response = await axios.post('/login', {
      username: username,
      password: password
    })

    if (response.data && response.data.success && response.data.token) {
      token.value = response.data.token
      user.value = response.data.user
      localStorage.setItem(tokenKey, token.value)
      localStorage.setItem(userKey, JSON.stringify(user.value))
      return true
    }
  } catch (error) {
    console.error('Login error:', error)
  }

  return false
}

export function useToken (): Ref<string> {
  return token
}

export function useUser (): Ref {
  return user
}

export function logout (): void {
  token.value = ''
  user.value = null
  localStorage.removeItem(tokenKey)
  localStorage.removeItem(userKey)
}

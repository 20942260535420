<template>
  <div class="container">
    <h1>Login</h1>

    <input v-model="username" type="text" placeholder="E-Mail" /><br />
    <input v-model="password" type="password" placeholder="Passwort" /><br />
    <button @click="onLogin">Einloggen</button>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { login, useUser } from '@/utils/login'
import { useRouter } from 'vue-router'

export default {
  name: 'Login',
  setup () {
    const username = ref('')
    const password = ref('')
    const router = useRouter()
    const user = useUser()

    async function onLogin () {
      if (username.value && password.value) {
        const response = await login(username.value, password.value)

        if (response) {
          // Erfolgreicher Login, leiten Sie den Benutzer zur Hauptseite weiter
          router.push('/')
        } else {
          alert('Benutzername oder Passwort falsch')
        }
      }
    }

    return {
      username,
      password,
      onLogin
    }
  }
}
</script>

<style scoped>
.test-info {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 0.9em;
}
</style>

<template>
  <div id="nav" v-if="token">
    <router-link to="/">Home</router-link> |
    <router-link to="/pos">Pos</router-link> |
    <a @click.prevent="onLogout" href="#">Logout</a>
  </div>
  <router-view v-if="token"/>
  <Login v-else />
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import { logout, useToken, useUser } from '@/utils/login'
import Login from '@/components/Login.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'App',
  components: { Login },
  setup () {
    const token = useToken()
    const user = useUser()
    const router = useRouter()

    function onLogout () {
      logout()
      router.push('/login')
    }

    watch(token, (newToken) => {
      if (!newToken) {
        router.push('/login')
      }
    })

    return {
      token,
      user,
      onLogout
    }
  }
})
</script>

<style lang="scss">
@font-face {
  font-family: 'primeicons';
  font-display: auto;
  src: url('/fonts/primeicons.eot');
  src: url('/fonts/primeicons.eot?#iefix') format('embedded-opentype'), url('/fonts/primeicons.ttf') format('truetype'), url('/fonts/primeicons.woff') format('woff'), url('/fonts/primeicons.svg?#primeicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import "assets/primeicons.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body {
  background-color: #b3dcff;
}

h1 {
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 10px auto;
  background-color: white;
  min-height: 90vh;
  padding: 20px;
}

.p-autocomplete {
  width: 600px;

  input {
    width: 100%;
    padding: 5px 20px;
  }
}
</style>
